export const environment = {
    production: true,
    debug: false,
    apiUrl: '/api',
    pdfUrl: 'https://api.prm.bvgo.acc.recognize.dev/api',
    logo: '/assets/images/logo.svg',
    applicationInsights: {
        instrumentationKey: '3a7703c7-1ceb-41da-be70-3deea2c9f5fc' //  hosted @ BVGO, resource group BVGO-WE-RSG-AP-PRM
    },
    msAuth: {
        issuer: 'https://login.microsoftonline.com/common/v2.0',
        clientId: '4f58e689-a35d-4737-851d-fa167ff1e04d',
        redirectUri: `${window.location.origin}/msal-auth`,
        scopes: ['api://4f58e689-a35d-4737-851d-fa167ff1e04d/user.read'],
        redirectToExternalEndSessionEndpointAfterLogout: false,
        redirectRoute: {
            afterAuthentication: ['/'],
            noAuthentication: ['/no-access'],
            failedAuthentication: ['/error']
        },
        // Use LocalStorage to allow same authentication across multiple browser tabs and persistence of tokens
        sessionStorageDriver: localStorage
    },
    features: {
        accessibleSteps: {
            enabled: false,
            showSetToDefaultButton: false // Useful for testing / debugging
        },
        apiProjectCreation: {
            enabled: true
        }
    }
};
